import type { AuthConfig, AuthUtilities } from '@urql/exchange-auth';
import { getSafeExamBrowserHttpHeaders } from './safeExamBrowser';
import { env } from './config';
import { store } from '~/store/store';
let currentToken: string;
let tokenExpiresAt: Date;

export const getToken = () => {
    return currentToken;
};

export const LOGIN_REDIRECT = 'login_redirect';

const loginApiUrl = `${env.VITE_API_BASE_URL}${env.VITE_LOGIN_CHECK_API_ENDPOINT}`;

const refreshTokenApiUrl = `${env.VITE_API_BASE_URL}${env.VITE_REFRESH_TOKEN_API_ENDPOINT}`;

const featuresApiUrl = `${env.VITE_API_BASE_URL}${env.VITE_FEATURES_API_ENDPOINT}`;

const logoutApiUrl = `${env.VITE_API_BASE_URL}${env.VITE_LOGOUT_API_ENDPOINT}`;

const oicdLogoutApiUrl = `${window.location.origin}${env.VITE_OICD_LOGOUT_ENDPOINT}`;

const setToken = (token): void => {
    tokenExpiresAt = new Date(
        Date.now() + parseInt(env.VITE_REFRESH_TOKEN_EXPIRE_TIME) * 1000 * 0.8
    ); // 80% of the time
    currentToken = token;
};

export const clearLoginSession = () => {
    currentToken = 'logged-out';
    tokenExpiresAt = new Date();

    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('logout', Date.now().toString());
};

export const isTokenExpired = () => {
    const token = getToken();
    if (!token || !tokenExpiresAt) return true;
    return tokenExpiresAt < new Date();
};

export const logIn = async (username: string, password: string) => {
    const response = await fetch(loginApiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    // reset previous impersonation, because permissions might have changed
    store?.getActions()?.userImpersonation?.resetImpersonatedUser();
    setToken(data.token);

    return data;
};

export const refresh = async () => {
    const response = await fetch(refreshTokenApiUrl, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    const data = await response.json();
    setToken(data.token);

    return currentToken;
};

export const logOut = async (preventRedirect?: boolean) => {
    const response = await fetch(featuresApiUrl);
    const features = await response.json();
    const isOrigin = window.location.pathname === '/';

    const href =
        preventRedirect || isOrigin
            ? '/login'
            : `/login?${LOGIN_REDIRECT}=${encodeURIComponent(
                  window.location.href
              )}`;

    let path = logoutApiUrl;

    if (features?.oidc) {
        path = oicdLogoutApiUrl;
        window.localStorage.setItem(LOGIN_REDIRECT, window.location.href);
    }

    clearLoginSession();

    try {
        const response = await fetch(path, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        const data = await response.json();

        if (data?.redirect) {
            window.location.href = data.redirect;
        } else {
            window.location.href = href;
        }
    } catch {
        window.location.href = href;
    }
};

export const getValidToken = async () => {
    const token = getToken();
    const isExpired = isTokenExpired();

    return isExpired ? await refresh() : token;
};

/**
 * Init function for passing to urql's authExchange(init: ...);
 * @link https://formidable.com/open-source/urql/docs/advanced/authentication/
 */
// eslint-disable-next-line require-await
export async function smlUrqlAuthExchangeInit(
    utils: AuthUtilities
): Promise<AuthConfig> {
    const token = getToken();
    const switchUser =
        store?.getState()?.userImpersonation?.impersonatedUser || '';

    return {
        willAuthError() {
            // Info: Extend with conditional checks if there are GraphQL endpoints
            // that can and should be called unauthenticated.
            // See https://formidable.com/open-source/urql/docs/advanced/authentication/#configuring-willautherror
            return isTokenExpired();
        },
        async refreshAuth() {
            const newToken = await refresh();

            if (!newToken) {
                await logOut();
            }
        },
        addAuthToOperation(operation) {
            if (!token) return operation;

            return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
                'X-Switch-User': switchUser,
                ...getSafeExamBrowserHttpHeaders(),
            });
        },
        didAuthError(error, operation) {
            if (error.response?.status === 403) {
                // When any currentUser queries fail with a 403, which in this case is
                // the signal for when the user does not exist, redirect to the login page.
                // This case will apply when teachers try to switch to users that don't exist.
                // Technically, there are better solutions to solve this UX wise, yet we used
                // this behaviour for years.
                if (
                    operation.kind === 'query' &&
                    operation.query.definitions.some(
                        (definition) =>
                            definition.kind === 'OperationDefinition' &&
                            definition.selectionSet.selections.some(
                                (node) =>
                                    node.kind === 'Field' &&
                                    node.name.value === 'currentUser'
                            )
                    )
                ) {
                    void logOut();
                }
            }

            return error.response?.status === 401;
        },
    };
}
