import {
    action,
    Action,
    computed,
    Computed,
    EffectOn,
    effectOn,
    persist,
    thunk,
    Thunk,
} from 'easy-peasy';
import type { EntityType } from '~/lib/types/EntityType';
import type { User } from '~/types/graphql';
import { StoreModel } from './model';

type UserMode = 'author' | 'student';
type UserModeUser = Pick<User, 'canAccessTeacherArea'>;

/**
 * Represents a store model for user modes
 */
export interface UserModeModel {
    /**
     * The current user mode. Authors, teachers, admins etc. can be in both, Author and Student mode.
     * They can be both, attendes and authors of exam events.
     */
    mode: UserMode;

    /**
     * List of user modes that are allowed to switch to
     */
    allowedModes: UserMode[];

    /**
     * Depending on user mode, the user is either seeing exam passes or exam events. This computed property
     * returns the primary entity modes based on the current userMode.
     */
    primaryEntityType: Computed<UserModeModel, EntityType>;

    /**
     * Sets the allowed modes for the user.
     */
    setAllowedModes: Action<UserModeModel, UserModeModel['allowedModes']>;

    /**
     * Switch between the different user modes.
     * Does not check for allowed user modes.
     */
    setMode: Action<UserModeModel, UserModeModel['mode']>;

    /**
     * Provide a user object from the smartlearn API to set the user mode mode based on the user permissions.
     */
    setModeByUser: Thunk<UserModeModel, UserModeUser>;

    /**
     * Checks if the user is allowed to switch to the requested user mode and changes the user mode if allowed.
     */
    validateAndSetMode: Thunk<
        UserModeModel,
        { userMode: UserModeModel['mode']; user?: UserModeUser }
    >;

    /**
     * Thunk that sets the store state based on the given user object from the API.
     * Sets allowed modes and initial mode if not set.
     */
    setByUser: Thunk<UserModeModel, UserModeUser>;

    /**
     * Update mode and allowed modes whenever a user is selected for impersonation.
     */
    onUserImpersonationChange: EffectOn<UserModeModel, StoreModel>;
}

/**
 * Stores the user mode, previously calleds teacher context. Decides whether the user is shown exam passes or exam events.
 */
export const userModeModel: UserModeModel = persist(
    {
        mode: null,
        allowedModes: ['student'],
        primaryEntityType: computed((state) =>
            state.mode === 'author' ? 'event' : 'pass'
        ),
        setAllowedModes: action((state, payload) => {
            state.allowedModes = payload;
        }),
        setMode: action((state, payload) => {
            state.mode = payload;
        }),
        setModeByUser: thunk((actions, payload) => {
            const userMode = payload.canAccessTeacherArea
                ? 'author'
                : 'student';

            actions.setMode(userMode);
        }),
        validateAndSetMode: thunk(
            (actions, { userMode, user }, { getState }) => {
                if (user) {
                    actions.setByUser(user);
                }

                const allowedUserModes = getState()?.allowedModes;

                if (allowedUserModes.includes(userMode)) {
                    actions.setMode(userMode);
                } else {
                    console.error('User mode not allowed');
                }
            }
        ),
        setByUser: thunk((actions, payload, { getState }) => {
            const allowedUserModes: UserModeModel['allowedModes'] = payload?.canAccessTeacherArea
                ? ['author', 'student']
                : ['student'];

            actions.setAllowedModes(allowedUserModes);

            if (
                getState().mode === null ||
                !allowedUserModes.includes(getState().mode)
            ) {
                actions.setModeByUser(payload);
            }
        }),
        onUserImpersonationChange: effectOn(
            [(_, storeState) => storeState.userImpersonation.impersonatedUser],
            (actions) => {
                actions.setMode(null);
            }
        ),
    },
    {
        allow: ['mode', 'allowedModes'],
    }
);
