import { action, Action, effectOn, EffectOn, persist } from 'easy-peasy';
import type { ExamListFilterUrlSearchParams } from '~/components/shared/Dashboard/hooks/useExamListFilterState';
import type { SmlSelectItem } from '~/components/ui/Select/SmlSelect';
import type { StoreModel } from './model';

/**
 * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
 */
const getCourse = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).course ?? null;
    } catch (e) {
        return null;
    }
};

/**
 * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
 */
const getCreatedBy = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).createdBy ?? '';
    } catch (e) {
        return '';
    }
};

/**
 * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
 */
const getSearchName = () => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        return JSON.parse(dashboardFilter).searchName ?? '';
    } catch (e) {
        return '';
    }
};

/**
 * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
 */
const saveFilter = (item: { [key: string]: any }) => {
    try {
        const dashboardFilter = window.localStorage.getItem(
            'smartlearn.dashboardFilter'
        );
        const json = JSON.parse(dashboardFilter);
        window.localStorage.setItem(
            'smartlearn.dashboardFilter',
            JSON.stringify({ ...json, ...item })
        );
    } catch (e) {
        window.localStorage.setItem(
            'smartlearn.dashboardFilter',
            JSON.stringify(item)
        );
    }
};

type FilterCount = {
    pilotCount: number;
    variantCount: number;
    eventsCount: number;
    passesCount: number;
    favouriteCount: number;
};

export interface DashboardFilterModel {
    activeFilters: ExamListFilterUrlSearchParams;
    saveActiveFilters: Action<
        DashboardFilterModel,
        DashboardFilterModel['activeFilters']
    >;

    /**
     * Reset any active filters to the stores initial state
     */
    clearActiveFilters: Action<DashboardFilterModel>;

    /**
     * Filter handler for when the user mode in the user mode store changes
     */
    onUserModeChange: EffectOn<DashboardFilterModel, StoreModel>;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    course: SmlSelectItem;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    createdBy: string;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    searchName: string;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    changeCourse: Action<DashboardFilterModel, SmlSelectItem>;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    changeCreatedBy: Action<DashboardFilterModel, string>;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    changeSearchName: Action<DashboardFilterModel, string>;

    filterCount: FilterCount;

    /**
     * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
     */
    updateFilter: Action<DashboardFilterModel, FilterCount>;
}

export const dashboardFilterModel: DashboardFilterModel = persist(
    {
        activeFilters: {},
        saveActiveFilters: action((state, payload) => {
            state.activeFilters = payload;
        }),

        clearActiveFilters: action((state) => {
            state.activeFilters = {};
        }),

        onUserModeChange: effectOn(
            [(_, storeState) => storeState.userMode.mode],
            (actions) => {
                actions.clearActiveFilters();
            }
        ),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        course: getCourse(),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        createdBy: getCreatedBy(),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        searchName: getSearchName(),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        changeCourse: action((state, payload) => {
            saveFilter({ course: payload });
            state.course = payload;
        }),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        changeCreatedBy: action((state, payload) => {
            saveFilter({ createdBy: payload });
            state.createdBy = payload;
        }),

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        changeSearchName: action((state, payload) => {
            saveFilter({ searchName: payload });
            state.searchName = payload;
        }),

        filterCount: {
            pilotCount: 0,
            variantCount: 0,
            eventsCount: 0,
            passesCount: 0,
            favouriteCount: 0,
        },

        /**
         * @deprecated Marked for removal without replacement. `activeFilters` is the new API.
         */
        updateFilter: action((state, payload) => {
            state.filterCount = payload;
        }),
    },
    {
        allow: ['activeFilters'],
    }
);
