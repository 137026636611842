import { action, Action, EffectOn, effectOn, persist } from 'easy-peasy';
import { AvailableLocale } from '~/lib/types/AvailableLocales';
import type { StoreModel } from './model';

export interface LocaleModel {
    locale: AvailableLocale;
    changeLocale: Action<LocaleModel, AvailableLocale>;
    onLocaleChanged: EffectOn<LocaleModel, StoreModel>;
}

export const localeModel: LocaleModel = persist(
    {
        locale: 'de',
        changeLocale: action((state, payload) => {
            state.locale = payload;
        }),
        onLocaleChanged: effectOn(
            [(_, storeState) => storeState.locale.locale],
            (_, { current }) => {
                // FIXME: Shouldn't be handled like that. Should be handled with react directly
                document.documentElement.setAttribute('lang', current[0]);
            }
        ),
    },
    {
        allow: ['locale'],
        storage: 'localStorage',
    }
);
