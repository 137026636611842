import { action, Action, persist, thunk, Thunk } from 'easy-peasy';

export interface UserImpersonationModel {
    /**
     * Username of currently impersonated user
     */
    impersonatedUser: string | null;

    /**
     * Set username of currently impersonated user
     */
    setImpersonatedUser: Action<UserImpersonationModel, string | null>;

    /**
     * Clear impersonation session
     */
    resetImpersonatedUser: Thunk<UserImpersonationModel>;
}

/**
 * Stores information about the user impersonation. Is separated from
 * UserModes as user modes are per browser window/tab session (sessionStorage), whereas impersonation
 * is for all browser sessions (localStorage)
 */
export const userImpersonationModel: UserImpersonationModel = persist(
    {
        impersonatedUser: null,
        setImpersonatedUser: action((state, payload) => {
            state.impersonatedUser = payload;
        }),
        resetImpersonatedUser: thunk((actions) => {
            actions.setImpersonatedUser(null);
        }),
    },
    {
        storage: 'localStorage',
        allow: ['impersonatedUser'],
    }
);
