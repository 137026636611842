import { devtoolsExchange } from '@urql/devtools';
import type { Cache as GraphCache } from '@urql/exchange-graphcache';
import { cacheExchange } from '@urql/exchange-graphcache';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import {
    Block,
    BlockConnection,
    BlockEdge,
    MatrixChoiceAnswer,
    MutationAddGroupsToExamEventArgs,
    MutationAddMachineToEnvironmentTemplateArgs,
    MutationAddUsersToExamEventArgs,
    MutationArchiveExamArgs,
    MutationBeginExamPassArgs,
    MutationCopyPasteAssignmentArgs,
    MutationCreateDiskTemplateArgs,
    MutationCreateExamArgs,
    MutationCreateExamEventArgs,
    MutationCreateVariantExamArgs,
    MutationDeleteAssignmentArgs,
    MutationDeleteDiskTemplateArgs,
    MutationDeleteExamArgs,
    MutationDeleteExamEventArgs,
    MutationDeleteExamPassArgs,
    MutationDeleteMediaObjectArgs,
    MutationExtendAutoArchiveExamArgs,
    MutationExtendAutoArchiveExamEventArgs,
    MutationFinishExamPassArgs,
    MutationRemoveMachineFromEnvironmentTemplateArgs,
    MutationRemoveUsersFromExamEventArgs,
    MutationUpdateAssignmentArgs,
    MutationUpdateBlockArgs,
    MutationUpdateExamArgs,
    MutationUpdateTaskBlockArgs,
    MutationUpdateUserArgs,
    MutationUploadMediaObjectArgs,
    RightWrongDontKnowAnswer,
} from 'types/graphql';
import { ClientOptions, fetchExchange, subscriptionExchange } from 'urql';
import {
    AssignmentDataFragment,
    AssignmentDataFragmentDoc,
} from '~/components/Blocks/AssignmentData.generated';
import {
    AssignmentDocument,
    AssignmentQuery,
} from '~/components/Blocks/AssignmentQuery.generated';
import { BlockDataFragment } from '~/components/Blocks/graphql/BlockDataFragment.generated';
import { ReorderBlockMutation } from '~/components/Blocks/BlockMutationReorder.generated';
import { CreateAssignmentMutation } from '~/components/Blocks/CreateAssignment.generated';
import { DeleteAssignmentMutation } from '~/components/Blocks/DeleteAssignment.generated';
import { FileUploadAnswerDataDocument } from '~/components/Blocks/Nodes/FileUpload/AnswerQuery.generated';
import { FilesBlockDataDocument } from '~/components/Blocks/Nodes/Files/BlockQuery.generated';
import { UpdateMultipleChoiceBlockMutation } from '~/components/Blocks/Nodes/MultipleChoice/BlockMutationUpdate.generated';
import { UpdateSequenceBlockMutation } from '~/components/Blocks/Nodes/Sequence/BlockMutationUpdate.generated';
import { UpdateSingleChoiceBlockMutation } from '~/components/Blocks/Nodes/SingleChoice/BlockMutationUpdate.generated';
import { UpdateTaskBlockBlockMutation } from '~/components/Blocks/Nodes/TaskBlock/BlockMutationUpdate.generated';
import {
    TaskBlockBlockDataDocument,
    TaskBlockBlockDataQuery,
} from '~/components/Blocks/Nodes/TaskBlock/BlockQuery.generated';
import { UpdateAssignmentMutation } from '~/components/Blocks/UpdateAssignment.generated';
import { DeleteMediaObjectMutation } from '~/components/shared/DeleteMediaObjectMutation.generated';
import { UploadMediaObjectMutation } from '~/components/shared/UploadMediaObjectMutation.generated';
import { CreateEventMutation } from '~/components/teacher/InnerPage/Events/CreateEventMutation.generated';
import { AddMachineMutation } from '~/components/teacher/InnerPage/VirtualEnvironment/AddMachine/AddMachineMutation.generated';
import { CreateExamMutation } from '~/components/teacher/Selection/Exams/CreateExam/CreateExamMutation.generated';
import { DeleteExamMutation } from '~/components/teacher/Selection/Exams/DeleteExam/DeleteExamMutation.generated';
import { ExamListDocument } from '~/components/teacher/course/ExamListQuery.generated';
import { getToken, smlUrqlAuthExchangeInit } from '~/lib/auth';
import { dedupFragmentsExchange } from '~/lib/dedupFragmentExchange';
import { forwardSubscription } from '~/lib/forwardSubscription';
import { getSafeExamBrowserHttpHeaders } from '~/lib/safeExamBrowser';
import scalarExchange from '~/lib/scalarExchange';
import { dateToString } from '~/lib/util/dateToString';
import { fromEntries } from '~/lib/util/fromEntries';
import { stringToDate } from '~/lib/util/stringToDate';
import { CurrentUserDocument } from '~/components/CurrentUserQuery.generated';
import { ExamEventExtendAutoArchiveMutation } from '~/components/cockpit/ExamPass/ExamEventExtendAutoArchiveMutation.generated';
import { ExamEventDocument } from '~/components/cockpit/ExamPass/ExamEventQuery.generated';
import { UpdateUserMutation } from '~/components/shared/UserUpdateMutation.generated';
import { DeleteExamEventMutation } from '~/components/teacher/InnerPage/Events/DeleteExamEventMutation.generated';
import {
    ExamAuthorDocument,
    ExamAuthorExtendAutoArchiveMutation,
} from '~/components/teacher/InnerPage/General/graphql/ExamAuthorQuery.generated';
import { ArchiveExamMutation } from '~/components/teacher/Selection/Exams/DeleteExam/ArchiveExamMutation.generated';
import introSchema from '~/intro-graphql.json';
import jsonSchema from '~/schema-graphql.json';
import { TaskBlockBlockFragmentDoc } from '~/components/Blocks/Nodes/TaskBlock/BlockFragment.generated';
import { authExchange } from '@urql/exchange-auth';
import { UpdateUsersFavouritesMutation } from '~/components/shared/Dashboard/UpdateUsersFavouritesMutation.generated';
import { ExamPrimaryInstanceMachinesDocument } from './graphql/ExamPrimaryInstanceMachinesQuery.generated';
import { MachineTemplateDocument } from '~/components/teacher/InnerPage/VirtualEnvironment/VmModal/MachineTemplateQuery.generated';
import { CreateVmHddTemplateMutation } from '~/components/teacher/InnerPage/VirtualEnvironment/CreateVmHddTemplateMutation.generated';
import { DeleteVmHddTemplateMutation } from '~/components/teacher/InnerPage/VirtualEnvironment/DeleteVmHddTemplateMutation.generated';
import { updateBlockPositions } from '~/components/Blocks/lib/updateBlockPositions';
import { updateBlockPositionOnConnection } from '~/components/Blocks/lib/updateBlockPositionOnConnection';
import { uncapitalize } from './util/strings';
import { createCorrectionApprovalUpdate } from './urql/resolvers/correctionApproval/createCorrectionApprovalUpdate';
import { deleteCorrectionApprovalUpdate } from './urql/resolvers/correctionApproval/deleteCorrectionApprovalUpdate';
import { env } from './config';
import { CopyPasteAssignmentMutation } from '~/components/Blocks/CopyPasteAssignmentMutation.generated';
import { AssignmentUpdateCacheFragmentDoc } from './urql/resolvers/assignments/graphql/AssignmentUpdateCacheFragment.generated';
import { AssignmentsDocument } from '~/components/teacher/InnerPage/Assignments/AssignmentsQuery.generated';
import { DisplayFileTransferBlockAnswerDocument } from '~/components/Blocks/Nodes/FileTransfer/graphql/DisplayFileTransferBlockAnswerQuery.generated';
import { ExamCreateVariantMutation } from '~/components/teacher/Selection/Exams/CreateExam/ExamCreateVariantMutation.generated';
import { store } from '~/store/store';

const graphqlApiUrl = `${env.VITE_API_BASE_URL}${env.VITE_GRAPHQL_API_ENDPOINT}`;

const handleBlockUpdate = (
    cache: GraphCache,
    block: { taskBlock?: { id?: string } } | { assignment?: { id?: string } },
    update: (blockConnection: BlockConnection) => BlockConnection
) => {
    if (!block) {
        return;
    }

    if ('taskBlock' in block && block?.taskBlock?.id) {
        cache.updateQuery(
            {
                query: TaskBlockBlockDataDocument,
                variables: { id: block.taskBlock.id },
            },
            (data: TaskBlockBlockDataQuery) => {
                // TODO: Fix Typing here somehow
                // @ts-ignore
                data.taskBlock.blocks = update(data.taskBlock.blocks);
                return data;
            }
        );
    }

    if ('assignment' in block && block.assignment?.id) {
        cache.updateQuery(
            {
                query: AssignmentDocument,
                variables: { id: block.assignment.id },
            },
            (data: AssignmentQuery) => {
                // TODO: Fix Typing here somehow
                // @ts-ignore
                data.assignment.blocks = update(data.assignment.blocks);
                return data;
            }
        );
    }
};

const getBlockMutations = () => {
    const blockTypes = [
        'RichTextBlock',
        'FilesBlock',
        'CodeBlock',
        'ScreenshotBlock',
        'FileUploadBlock',
        'FileTransferBlock',
        'CodeAreaBlock',
        'TextInputBlock',
        'SingleChoiceBlock',
        'SequenceBlock',
        'MultipleChoiceBlock',
        'MatrixChoiceBlock',
        'RightWrongAssertionBlock',
        'RightWrongDontKnowBlock',
        'H5pBlock',
        'TaskBlock',
    ];

    const createMutations = fromEntries(
        blockTypes.map((blockType) => {
            const createHandler = (result, _args, cache) => {
                const block = result[`create${blockType}`]?.[
                    uncapitalize(blockType)
                ] as BlockDataFragment;

                handleBlockUpdate(cache, block, (blockConnection) => {
                    // Add the new block as an edge at the end of all edges.
                    // Later, sort. We put some fake cursor to it, it shouldn't matter as long as it is unique,
                    // as it will not be shared with the server and be replaced on the next paginated request.
                    const newEdge: BlockEdge = {
                        __typename: 'BlockEdge',
                        node: (block as unknown) as Block,
                        cursor: block.id,
                    };

                    const connectionWithMergedEdges = {
                        ...blockConnection,
                        edges: [...blockConnection.edges, newEdge],
                    };

                    // Sort and return the list with the newly added block.
                    return updateBlockPositionOnConnection(
                        { id: block.id, position: block.position },
                        connectionWithMergedEdges
                    );
                });
            };

            return [`create${blockType}`, createHandler] as const;
        })
    );

    const deleteMutations = fromEntries(
        blockTypes.map((blockType) => {
            const deleteHandler = (result, _args, cache) => {
                const block = result[`delete${blockType}`]?.[
                    uncapitalize(blockType)
                ] as BlockDataFragment;

                handleBlockUpdate(cache, block, (blockConnection) => {
                    const indexOfItem = blockConnection.edges?.findIndex(
                        (edge) => edge.node.id === block.id
                    );

                    if (indexOfItem !== -1) {
                        const remainingEdges = [...blockConnection.edges];
                        remainingEdges.splice(indexOfItem, 1);

                        return updateBlockPositions({
                            ...blockConnection,
                            edges: remainingEdges,
                        });
                    }

                    return updateBlockPositions(blockConnection);
                });
            };

            return [`delete${blockType}`, deleteHandler] as const;
        })
    );

    return { ...createMutations, ...deleteMutations };
};

const moveInSortedAssignments = <T extends AssignmentDataFragment>(
    connection: T[],
    item: T
): T[] => {
    const rest = connection.filter((it) => it.id !== item.id);
    const parentList = rest.map((it) => it.parent?.id);
    const parentSet = new Set([...parentList, item.parent?.id]);

    let arr = [];
    parentSet.forEach((parentId) => {
        const list = rest.filter((it) => it.parent?.id === parentId);

        if (item.parent?.id === parentId) {
            list.splice(item.position, 0, {
                ...item,
                __typename: 'Assignment',
            });
        }

        const ordered = list.map((it, index) => ({ ...it, position: index }));
        arr = [...arr, ...ordered];
    });

    return arr;
};

const invalidateUnfilledBlocksInTaskBlocksQuery = (
    _result,
    _args,
    cache: GraphCache
) => {
    const key = 'Query';
    cache
        .inspectFields(key)
        .filter(
            (field) => field.fieldName === 'unfilledBlocksInTaskBlockBlocks'
        )
        .forEach((field) => {
            cache.invalidate(key, field.fieldKey);
        });
};

export const clientOptions: ClientOptions = {
    url: graphqlApiUrl,
    suspense: true,
    requestPolicy: 'cache-and-network',
    exchanges: [
        devtoolsExchange,
        scalarExchange({
            // @ts-ignore
            schema: introSchema,
            scalars: {
                DateTime: {
                    serialize: dateToString,
                    deserialize: stringToDate,
                },
            },
        }),
        cacheExchange({
            schema: jsonSchema,
            resolvers: {
                Query: {
                    allBlocksByExamBlocks: relayPagination(),
                    blocks: relayPagination(),
                    courses: relayPagination(),
                    exams: relayPagination(),
                    examEvents: relayPagination(),
                    examPassLogs: relayPagination(),
                    unfilledBlocksInTaskBlockBlocks: relayPagination(),
                },
                Assignment: {
                    blocks: relayPagination(),
                },
                TaskBlock: {
                    blocks: relayPagination(),
                },
                RightWrongDontKnowAnswer: {
                    choices: (parent: RightWrongDontKnowAnswer) =>
                        // The API returns an empty array for when a user has no answers given yet. Put if the user has given answers prior, the API returns an object.
                        // We resolve this inconsistency by always casting it to an empty object. This is what the UI would expect.
                        !parent ||
                        (parent?.choices &&
                            Array.isArray(parent.choices) &&
                            parent.choices.length === 0)
                            ? {}
                            : parent.choices,
                },
                MatrixChoiceAnswer: {
                    answers: (parent: MatrixChoiceAnswer) =>
                        // The API returns an empty array for when a user has no answers given yet. Put if the user has given answers prior, the API returns an object.
                        // We resolve this inconsistency by always casting it to an empty object. This is what the UI would expect.
                        !parent ||
                        (parent?.answers &&
                            Array.isArray(parent.answers) &&
                            parent.answers.length === 0)
                            ? {}
                            : parent.answers,
                },
            },
            optimistic: {
                updateBlock: (variables: MutationUpdateBlockArgs) => {
                    return {
                        __typename: 'updateBlockPayload',
                        block: {
                            id: variables.input.id,
                            position: variables.input.position,
                            taskBlock: {
                                id: variables.input.taskBlock,
                            },
                            assignment: {
                                id: variables.input.assignment,
                            },
                        },
                    };
                },
                updateTaskBlock: (
                    variables: MutationUpdateTaskBlockArgs,
                    cache
                ) => {
                    const taskBlock = cache.readFragment(
                        TaskBlockBlockFragmentDoc,
                        { __typename: 'TaskBlock', id: variables.input.id }
                    );

                    return {
                        __typename: 'updateTaskBlockPayload',
                        taskBlock: {
                            ...taskBlock,
                            title: variables.input.title ?? taskBlock.title,
                        },
                    };
                },
                updateAssignment: (
                    variables: MutationUpdateAssignmentArgs,
                    cache
                ) => {
                    const assignment = cache.readFragment(
                        AssignmentDataFragmentDoc,
                        { __typename: 'Assignment', id: variables.input.id }
                    );

                    return {
                        __typename: 'updateAssignmentPayload',
                        assignment: {
                            ...assignment,
                            title: variables.input.title ?? assignment.title,
                            position:
                                variables.input.position ?? assignment.position,
                            parent: variables.input.parent
                                ? {
                                      id: variables.input.parent,
                                      __typename: 'Assignment',
                                  }
                                : null,
                        },
                    };
                },
            },
            updates: {
                Mutation: {
                    archiveExam: (
                        result: ArchiveExamMutation,
                        args: MutationArchiveExamArgs,
                        cache
                    ) => {
                        if (!result.archiveExam?.exam?.archivedAt) {
                            return;
                        }

                        // Ensure exam is pulled completetly to make flags like
                        // `canCreateVariant`` properly update all over the UI
                        cache.invalidate({
                            __typename: 'Exam',
                            id: result.archiveExam.exam.id,
                        });

                        // Forcefully invalidate all exam lists after archiving
                        // to ensure the dashboards requests their lists
                        // again with correct pagination and the correct states.
                        cache
                            .inspectFields('Query')
                            .filter((field) => field.fieldName === 'exams')
                            .forEach((field) => {
                                cache.invalidate('Query', field.fieldKey);
                            });
                    },
                    createCorrectionApproval: createCorrectionApprovalUpdate,
                    deleteCorrectionApproval: deleteCorrectionApprovalUpdate,
                    createExam: (
                        result: CreateExamMutation,
                        args: MutationCreateExamArgs,
                        cache
                    ) => {
                        if (!result.createExam) return;

                        const exam = result.createExam.exam;

                        cache
                            .inspectFields('Query')
                            .filter((field) => field.fieldName === 'exams')
                            .filter(
                                (field) =>
                                    !field.arguments.course ||
                                    field.arguments.course === args.input.course
                            )
                            .filter(
                                (field) =>
                                    field.arguments.isLongRunning ===
                                    args.input.isLongRunning
                            )
                            .filter(
                                (field) => field.arguments.after === undefined
                            )
                            .filter(
                                (field) =>
                                    !field.arguments.name ||
                                    exam.name.includes(
                                        '' + field.arguments.name
                                    )
                            )
                            // createExam always creates parent exams, so we skip queries that expect variants
                            .filter(
                                (field) =>
                                    !(
                                        Array.isArray(field.arguments.exists) &&
                                        field.arguments.exists.some(
                                            (it) => it.parent
                                        )
                                    )
                            )
                            // also skip queries for archived exams
                            .filter(
                                (field) =>
                                    !(
                                        Array.isArray(field.arguments.exists) &&
                                        field.arguments.exists.some(
                                            (it) => it.archivedAt
                                        )
                                    )
                            )
                            .forEach((field) => {
                                // the structure of query variables and cache key differ for the index filters, so we have to remap it
                                // (filtered out above, but kept here for createVariantExam implementation later)
                                field.arguments.hasParent =
                                    Array.isArray(field.arguments.exists) &&
                                    field.arguments.exists.some(
                                        (it) => it.parent
                                    );
                                field.arguments.isArchived =
                                    Array.isArray(field.arguments.exists) &&
                                    field.arguments.exists.some(
                                        (it) => it.archivedAt
                                    );
                                delete field.arguments.exists;
                                cache.updateQuery(
                                    {
                                        query: ExamListDocument,
                                        variables: field.arguments,
                                    },
                                    (data) => {
                                        if (data === null) return null;
                                        data.exams.edges.unshift({
                                            __typename: 'ExamEdge',
                                            node: exam,
                                        });
                                        data.exams.totalCount += 1;

                                        return data;
                                    }
                                );
                            });

                        // Invalidate all examEvents queries to ensure they are requests again from the server
                        // and therefore correctly apply new pagination values
                        cache
                            .inspectFields('Query')
                            .filter((field) => field.fieldName === 'exams')
                            .forEach((field) => {
                                cache.invalidate('Query', field.fieldKey);
                            });
                    },
                    updateExam: (
                        result,
                        args: MutationUpdateExamArgs,
                        cache
                    ) => {
                        if (!args.input.assignmentsEnabled) {
                            cache.invalidate(
                                {
                                    __typename: 'Exam',
                                    id: args.input.id,
                                },
                                'assignments'
                            );
                        }
                    },
                    createVariantExam: (
                        result: ExamCreateVariantMutation,
                        args: MutationCreateVariantExamArgs,
                        cache
                    ) => {
                        if (!result.createVariantExam) {
                            return;
                        }

                        cache
                            .inspectFields('Query')
                            .filter((field) => field.fieldName === 'exams')
                            .forEach((field) => {
                                cache.invalidate('Query', field.fieldKey);
                            });
                    },
                    deleteExam: (
                        result: DeleteExamMutation,
                        args: MutationDeleteExamArgs,
                        cache
                    ) => {
                        if (!result.deleteExam) {
                            return;
                        }

                        cache.invalidate({
                            __typename: 'Exam',
                            id: args.input.id,
                        });

                        // Forcefully invalidate all exam lists after deletion
                        // to ensure the dashboards requests their lists
                        // again with correct pagination and the correct states.
                        cache
                            .inspectFields('Query')
                            .filter((field) => field.fieldName === 'exams')
                            .forEach((field) => {
                                cache.invalidate('Query', field.fieldKey);
                            });
                    },
                    createExamEvent: (
                        result: CreateEventMutation,
                        args: MutationCreateExamEventArgs,
                        cache
                    ) => {
                        if (!result.createExamEvent) return;

                        // Invalidate all examEvents queries to ensure they are requests again from the server
                        // and therefore correctly apply new pagination values
                        const key = 'Query';
                        cache
                            .inspectFields(key)
                            .filter((field) => field.fieldName === 'examEvents')
                            .forEach((field) => {
                                cache.invalidate(key, field.fieldKey);
                            });

                        // Invalidate the `events` field on the exam
                        // Both invalidations are necessary because urql's cache
                        // makes a distinction between queries with and without filters.
                        cache.invalidate(
                            {
                                __typename: 'Exam',
                                id: args.input.examVariant,
                            },
                            'events'
                        );

                        cache.invalidate(
                            {
                                __typename: 'Exam',
                                id: args.input.examVariant,
                            },
                            'events',
                            { exists: [{ archivedAt: false }] }
                        );
                    },
                    deleteExamEvent: (
                        result: DeleteExamEventMutation,
                        args: MutationDeleteExamEventArgs,
                        cache
                    ) => {
                        if (result.deleteExamEvent) {
                            cache.invalidate({
                                __typename: 'ExamEvent',
                                id: args.input.id,
                            });

                            // Invalidate the `events` field on the exam
                            // Both invalidations are necessary because urql's cache
                            // makes a distinction between queries with and without filters.
                            cache.invalidate(
                                {
                                    __typename: 'Exam',
                                    id:
                                        result.deleteExamEvent.examEvent
                                            ?.examVariant.id,
                                },
                                'events'
                            );

                            cache.invalidate(
                                {
                                    __typename: 'Exam',
                                    id:
                                        result.deleteExamEvent.examEvent
                                            ?.examVariant.id,
                                },
                                'events',
                                { exists: [{ archivedAt: false }] }
                            );
                        }
                    },
                    extendAutoArchiveExam: (
                        result: ExamAuthorExtendAutoArchiveMutation,
                        args: MutationExtendAutoArchiveExamArgs,
                        cache
                    ) => {
                        if (!result.extendAutoArchiveExam) {
                            return;
                        }

                        const {
                            autoArchiveWarned,
                            autoArchiveDate,
                        } = result.extendAutoArchiveExam.exam;

                        cache.updateQuery(
                            {
                                query: ExamAuthorDocument,
                                variables: { examId: args.input.id },
                            },
                            (data) => {
                                data.exam.autoArchiveDate = autoArchiveDate;
                                data.exam.autoArchiveWarned = autoArchiveWarned;

                                return data;
                            }
                        );
                    },
                    extendAutoArchiveExamEvent: (
                        result: ExamEventExtendAutoArchiveMutation,
                        args: MutationExtendAutoArchiveExamEventArgs,
                        cache
                    ) => {
                        if (!result.extendAutoArchiveExamEvent) {
                            return;
                        }

                        const {
                            autoArchiveWarned,
                            autoArchiveDate,
                        } = result.extendAutoArchiveExamEvent.examEvent;

                        cache.updateQuery(
                            {
                                query: ExamEventDocument,
                                variables: { eventId: args.input.id },
                            },
                            (data) => {
                                data.examEvent.autoArchiveDate = autoArchiveDate;
                                data.examEvent.autoArchiveWarned = autoArchiveWarned;

                                return data;
                            }
                        );
                    },
                    removeMachineFromEnvironmentTemplate: (
                        _result,
                        args: MutationRemoveMachineFromEnvironmentTemplateArgs,
                        cache,
                        info
                    ) => {
                        const environmentTemplateId = args.input.id;
                        const machineTemplateId = args.input.machine;

                        cache.invalidate(
                            {
                                __typename: 'EnvironmentTemplate',
                                id: environmentTemplateId,
                            },
                            'machines'
                        );

                        cache.invalidate({
                            __typename: 'MachineTemplate',
                            id: machineTemplateId,
                        });

                        // Extra variable not part of the Query itself
                        const examId = info?.variables?.examId as string;
                        const machineId = info?.variables?.machineId as string;

                        if (examId && machineId) {
                            // Remove machine with given machineId from given exam with given examId, to ensure
                            // `machines` of that exam's primary instance is not featuring a `null` reference.
                            cache.updateQuery(
                                {
                                    query: ExamPrimaryInstanceMachinesDocument,
                                    variables: { examId },
                                },
                                (data) => {
                                    data.exam.environment.primaryInstance.machines = data.exam.environment.primaryInstance.machines?.filter(
                                        (machine) => machine.id !== machineId
                                    );

                                    return data;
                                }
                            );
                        }
                    },
                    addMachineToEnvironmentTemplate: (
                        result: AddMachineMutation,
                        args: MutationAddMachineToEnvironmentTemplateArgs,
                        cache
                    ) => {
                        if (!result.addMachineToEnvironmentTemplate) {
                            return;
                        }

                        // Network graph
                        cache.invalidate(
                            {
                                __typename: 'Environment',
                                id:
                                    result?.addMachineToEnvironmentTemplate
                                        ?.environmentTemplate?.primaryInstance
                                        ?.id,
                            },
                            'machines'
                        );

                        // Tab count
                        cache.invalidate(
                            {
                                __typename: 'EnvironmentTemplate',
                                id: args.input.id,
                            },
                            'machines'
                        );
                    },
                    deleteDiskTemplate: (
                        result: DeleteVmHddTemplateMutation,
                        args: MutationDeleteDiskTemplateArgs,
                        cache
                    ) => {
                        cache.updateQuery(
                            {
                                query: MachineTemplateDocument,
                                variables: {
                                    id:
                                        result?.deleteDiskTemplate?.diskTemplate
                                            ?.machine?.id,
                                },
                            },
                            (data) => {
                                if (data?.machineTemplate?.disks) {
                                    data.machineTemplate.disks = data?.machineTemplate?.disks?.filter(
                                        (disk) => disk.id !== args.input.id
                                    );
                                }

                                return data;
                            }
                        );
                    },
                    createDiskTemplate: (
                        result: CreateVmHddTemplateMutation,
                        args: MutationCreateDiskTemplateArgs,
                        cache
                    ) => {
                        cache.updateQuery(
                            {
                                query: MachineTemplateDocument,
                                variables: { id: args.input.machine },
                            },
                            (data) => {
                                data?.machineTemplate?.disks?.push(
                                    result.createDiskTemplate.diskTemplate
                                );

                                return data;
                            }
                        );
                    },
                    addUsersToExamEvent: (
                        _result,
                        args: MutationAddUsersToExamEventArgs,
                        cache
                    ) => {
                        // TODO: don't invalidate, just update

                        cache.invalidate(
                            { __typename: 'ExamEvent', id: args.input.id },
                            'passes'
                        );
                    },
                    addGroupsToExamEvent: (
                        _result,
                        args: MutationAddGroupsToExamEventArgs,
                        cache
                    ) => {
                        // TODO: don't invalidate, just update
                        cache.invalidate(
                            { __typename: 'ExamEvent', id: args.input.id },
                            'passes'
                        );
                    },
                    removeUsersFromExamEvent: (
                        _result,
                        args: MutationRemoveUsersFromExamEventArgs,
                        cache
                    ) => {
                        // TODO: don't invalidate, just update
                        cache.invalidate(
                            { __typename: 'ExamEvent', id: args.input.id },
                            'passes'
                        );
                    },
                    deleteExamPass: (
                        _result,
                        args: MutationDeleteExamPassArgs,
                        cache
                    ) => {
                        const pass = {
                            __typename: 'ExamPass',
                            id: args.input.id,
                        };

                        cache.invalidate(pass);
                    },
                    beginExamPass: (
                        result,
                        args: MutationBeginExamPassArgs,
                        cache
                    ) => {
                        cache.invalidate({
                            __typename: 'ExamPass',
                            id: args.input.id,
                        });
                    },
                    finishExamPass: (
                        result,
                        args: MutationFinishExamPassArgs,
                        cache
                    ) => {
                        cache.invalidate({
                            __typename: 'ExamPass',
                            id: args.input.id,
                        });
                    },
                    ...getBlockMutations(),
                    uploadMediaObject: (
                        result: UploadMediaObjectMutation,
                        args: MutationUploadMediaObjectArgs,
                        cache
                    ) => {
                        const filesBlock = args.input.filesBlock;
                        if (filesBlock) {
                            cache.updateQuery(
                                {
                                    query: FilesBlockDataDocument,
                                    variables: { id: filesBlock },
                                },
                                (data) => {
                                    data.filesBlock.files.push(
                                        result.uploadMediaObject.mediaObject
                                    );
                                    return data;
                                }
                            );
                        }
                        const fileUploadAnswer = args.input.fileUploadAnswer;
                        if (fileUploadAnswer) {
                            invalidateUnfilledBlocksInTaskBlocksQuery(
                                result,
                                args,
                                cache
                            );
                            cache.updateQuery(
                                {
                                    query: FileUploadAnswerDataDocument,
                                    variables: { id: fileUploadAnswer },
                                },
                                (data) => {
                                    data.fileUploadAnswer.files.push(
                                        result.uploadMediaObject.mediaObject
                                    );
                                    return data;
                                }
                            );
                        }
                        const fileTransferAnswerCorrection =
                            args.input.fileTransferAnswerCorrection;
                        if (fileTransferAnswerCorrection) {
                            cache.updateQuery(
                                {
                                    query: DisplayFileTransferBlockAnswerDocument,
                                    variables: {
                                        answerId: fileTransferAnswerCorrection,
                                    },
                                },
                                (data) => {
                                    data.fileTransferAnswer.correctionFiles.push(
                                        result.uploadMediaObject.mediaObject
                                    );
                                    return data;
                                }
                            );
                        }
                    },
                    deleteMediaObject: (
                        result: DeleteMediaObjectMutation,
                        args: MutationDeleteMediaObjectArgs,
                        cache
                    ) => {
                        cache.invalidate(result.deleteMediaObject.mediaObject);
                    },
                    updateBlock: (
                        result: ReorderBlockMutation,
                        args: MutationUpdateBlockArgs,
                        cache,
                        info
                    ) => {
                        // Is passed as true if this is an update concerning the position of the block.
                        // This is an extra prop passed to the useMutation hook and not part of the mutation query itself.
                        const positionUpdate = info.variables
                            ?.positionUpdate as boolean;

                        if (positionUpdate) {
                            // Update `position` and reorder the blocks on the fly, after the optimistic
                            // update and after the network update.
                            handleBlockUpdate(
                                cache,
                                {
                                    assignment: {
                                        id: args.input.assignment,
                                    },
                                    taskBlock: {
                                        id: args.input.taskBlock,
                                    },
                                },
                                (blockConnection) =>
                                    updateBlockPositionOnConnection(
                                        {
                                            id: result.updateBlock.block.id,
                                            position:
                                                result.updateBlock.block
                                                    .position,
                                        },
                                        blockConnection
                                    )
                            );
                        }

                        return result;
                    },
                    updateSequenceBlock: (
                        result: UpdateSequenceBlockMutation,
                        _args,
                        cache
                    ) => {
                        cache.invalidate(
                            result.updateSequenceBlock.sequenceBlock.solution
                        );
                    },
                    updateSingleChoiceBlock: (
                        result: UpdateSingleChoiceBlockMutation,
                        _args,
                        cache
                    ) => {
                        cache.invalidate(
                            result.updateSingleChoiceBlock.singleChoiceBlock
                                .solution
                        );
                    },
                    updateMultipleChoiceBlock: (
                        result: UpdateMultipleChoiceBlockMutation,
                        _args,
                        cache
                    ) => {
                        cache.invalidate(
                            result.updateMultipleChoiceBlock.multipleChoiceBlock
                                .solution
                        );
                    },
                    createAssignment: (
                        result: CreateAssignmentMutation,
                        _args,
                        cache
                    ) => {
                        const assignment = result.createAssignment.assignment;

                        cache.updateQuery(
                            {
                                query: AssignmentsDocument,
                                variables: { exam: assignment.exam.id },
                            },
                            (data) => {
                                data.exam.assignments.push(assignment);

                                return data;
                            }
                        );
                    },
                    copyPasteAssignment: (
                        result: CopyPasteAssignmentMutation,
                        _args: MutationCopyPasteAssignmentArgs,
                        cache
                    ) => {
                        if (!result.copyPasteAssignment) {
                            return null;
                        }

                        const newAssignment =
                            result.copyPasteAssignment.assignment;

                        cache.invalidate(
                            {
                                __typename: 'Exam',
                                id: newAssignment.exam.id,
                            },
                            'assignments'
                        );
                    },
                    deleteAssignment: (
                        result: DeleteAssignmentMutation,
                        args: MutationDeleteAssignmentArgs,
                        cache
                    ) => {
                        if (!result.deleteAssignment) {
                            return;
                        }

                        cache.invalidate({
                            __typename: 'Assignment',
                            id: args.input.id,
                        });

                        cache.invalidate(
                            {
                                __typename: 'Exam',
                                id: result.deleteAssignment.assignment.exam.id,
                            },
                            'assignments'
                        );
                    },
                    updateAssignment: (
                        result: UpdateAssignmentMutation,
                        args: MutationUpdateAssignmentArgs,
                        cache
                    ) => {
                        if (!result) {
                            cache.invalidate({
                                __typename: 'Assignment',
                                id: args.input.id,
                            });
                        }

                        const assignment = result.updateAssignment?.assignment;

                        if (typeof args.input?.position === 'number') {
                            cache.invalidate(
                                {
                                    __typename: 'Assignment',
                                    id: assignment.id,
                                },
                                'position'
                            );
                        }

                        if (typeof args.input?.parent === 'string') {
                            cache.invalidate(
                                {
                                    __typename: 'Assignment',
                                    id: assignment.id,
                                },
                                'parent'
                            );
                        }

                        if (typeof args.input?.title === 'string') {
                            const assignment =
                                result.updateAssignment.assignment;

                            const title = assignment?.title;

                            cache.writeFragment(
                                AssignmentUpdateCacheFragmentDoc,
                                {
                                    __typename: 'Assignment',
                                    id: args.input.id,
                                    title,
                                    position: undefined,
                                    parent: undefined,
                                }
                            );
                        }

                        if (
                            args.input?.position !== undefined ||
                            args.input?.parent !== undefined
                        ) {
                            cache.invalidate(
                                {
                                    __typename: 'Exam',
                                    id: assignment.exam.id,
                                },
                                'assignments'
                            );
                        }
                    },
                    updateTaskBlock: (
                        result: UpdateTaskBlockBlockMutation,
                        args: MutationUpdateTaskBlockArgs,
                        cache
                    ) => {
                        const taskBlock = result.updateTaskBlock.taskBlock;

                        if (args.input.position !== undefined) {
                            handleBlockUpdate(
                                cache,
                                taskBlock,
                                (blockConnection) => {
                                    const updatedBlockConnection = updateBlockPositionOnConnection(
                                        taskBlock,
                                        blockConnection
                                    );

                                    return updatedBlockConnection;
                                }
                            );
                        } else {
                            cache.updateQuery(
                                {
                                    query: TaskBlockBlockDataDocument,
                                    variables: { id: taskBlock.id },
                                },
                                (data) => {
                                    data.taskBlock = {
                                        ...data.taskBlock,
                                        ...taskBlock,
                                    };

                                    return data;
                                }
                            );
                        }
                    },
                    updateSingleChoiceAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateTextInputAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateCodeAreaAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateMatrixChoiceAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateMultipleChoiceAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateRightWrongAssertionAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateRightWrongDontKnowAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateSequenceAnswer: invalidateUnfilledBlocksInTaskBlocksQuery,
                    updateUser: (
                        result:
                            | UpdateUserMutation
                            | UpdateUsersFavouritesMutation,
                        args: MutationUpdateUserArgs,
                        cache
                    ) => {
                        const updatedUser = result.updateUser.user;

                        cache.updateQuery(
                            {
                                query: CurrentUserDocument,
                            },
                            (data) => {
                                if (!data) {
                                    return;
                                }

                                data.currentUser = {
                                    ...data.currentUser,
                                    ...updatedUser,
                                };

                                return data;
                            }
                        );
                    },
                },
            },
        }),
        dedupFragmentsExchange,
        authExchange(smlUrqlAuthExchangeInit),
        fetchExchange,
        subscriptionExchange({ forwardSubscription }),
    ],
    fetchOptions: () => ({
        credentials: 'include',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'X-Switch-User':
                store?.getState()?.userImpersonation?.impersonatedUser || '',
            ...getSafeExamBrowserHttpHeaders(),
        },
    }),
};
