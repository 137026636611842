import { dashboardFilterModel, DashboardFilterModel } from './dashboardFilter';
import { localeModel, LocaleModel } from './locale';
import { NotificationsModel, notificationsModel } from './notifications';
import studentModel, { StudentModel } from './student';
import {
    UserImpersonationModel,
    userImpersonationModel,
} from './userImpersonation';
import { userModeModel, UserModeModel } from './userMode';

export interface StoreModel {
    dashboardFilter: DashboardFilterModel;
    locale: LocaleModel;
    student: StudentModel;
    notifications: NotificationsModel;
    userMode: UserModeModel;
    userImpersonation: UserImpersonationModel;
}

const storeModel: StoreModel = {
    dashboardFilter: dashboardFilterModel,
    locale: localeModel,
    student: studentModel,
    notifications: notificationsModel,
    userMode: userModeModel,
    userImpersonation: userImpersonationModel,
};

export default storeModel;
